import { Outlet, useOutletContext } from "react-router-dom";
import { HeaderNavbar, SideNavbar, NavFooter, MainContainer, MainFooter} from '@allsynx/components';
import Logo from "resources/tbhLogo.png";
import { useEffect, useState, useContext } from 'react';
import { urls } from "utils/environment-constants";
import { getTokenClaim } from "utils/jwt-util";
import { NavigationContext } from "contexts/navigation-context";
import { localStorageUtil } from "utils/local-storage-util";

function Settings() {
	const { sideBarNavItems,setFooterHasSideBar} = useContext(NavigationContext);

	const [userName, setUserName] = useState("");
	
	useEffect(() => {
		loadPageData().catch(console.error);
		setFooterHasSideBar(true);
	}, []);

	const loadPageData = async () => {
		setUserName(await getTokenClaim("sub") ?? "");
	}

	const logout = () => {
		localStorageUtil.clearAll();
		window.location.replace(urls.tbhBaseUrl + "/Logout.cfm");
	}

	return (
		<div className="container-outer">
			<HeaderNavbar imgSrc={Logo} rightElement= {
						<a id="LogoutLink" className="logout-user" onClick={logout} href="#">
							<span id="LogoutUser">Logout, {userName}</span>
						</a> 
					} 
			/>
			<SideNavbar navItems={sideBarNavItems} themeColor="blue"/> 
			<div id="settingsContainer" className="settings-container">
				
				<Outlet />	
					<br/>
			</div>
		</div>
	);
}

export default Settings;