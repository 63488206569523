import { BorderSpinner, FormSelect, Radio } from "@allsynx/components";
import { MfaContactSubTypes } from "enums/mfa-contact-sub-types";
import { MfaContactTypes } from "enums/mfa-contact-types";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { MfaContactOption } from "types/mfa-contact-option";
import { MfaResponse } from "types/mfa-response";
import { getAnonymous, postAnonymous} from "utils/api-util"
import { localStorageUtil } from "utils/local-storage-util";

function MfaOptions() {
	const [availableOptions, setAvailableOptions] = useState<MfaContactOption[]>([]);
	const [shownOptions, setShownOptions] = useState<MfaContactOption[]>([]);
	const [selectedType, setSelectedType] = useState(0);
	const [selectedSubType, setSelectedSubType] = useState(0);
	const [webDirectory, setWebDirectory] = useState("");
	const [isLoading, setIsLoading] = useState(false); // loading/spinner for after clicking submit button
	const [isInitialLoading, setIsInitialLoading] = useState(false); // loading/spinner for initial page load
	const [sendError, setSendError] = useState("");
	const [buttonText, setButtonText] = useState("Send Code");

	const [searchParams] = useSearchParams();

	const navigate = useNavigate();
	const setWebDirForLogo = useOutletContext<(src: string) => void>();

	useEffect(() => {
		const getMfaOptions = async () => {
			setIsInitialLoading(true);
			setAvailableOptions([]);
			setSelectedType(0);
			setSelectedSubType(0);
			const response = await getAnonymous<MfaContactOption[]>("/api/mfa/options");
			if (response?.ok && response.resObj) {
				await setAvailableOptions(response.resObj);
				if (response.resObj.length) {
					setSelectedType(response.resObj[0].type);
					handleSelectType(response.resObj[0].type.toString(), response.resObj)
				}
			}
			setIsInitialLoading(false);
		};

		getMfaOptions().catch(console.error);

		var webDir = localStorageUtil.getItem<string>('webDir');
		if (webDir) {
			setWebDirForLogo(webDir);
			setWebDirectory(webDir);
		}
		
	}, []);

	useEffect(() => {
		setShownOptions([]);

		var optionsToShow = availableOptions.filter(opt => opt.type === selectedType);
		setShownOptions([ ...optionsToShow ]);
	}, [selectedType, availableOptions]);

	const handleSelectOption = (option: string) => {
		var opt = !Number.isNaN(parseInt(option)) ? parseInt(option) : 0;
		setSelectedSubType(opt);
	};

	const handleSelectType = (typeSelected: string, options: MfaContactOption[] | null) => {
		var t = !Number.isNaN(parseInt(typeSelected)) ? parseInt(typeSelected) : 0;
		var optionsList = options ?? availableOptions;
		var option = optionsList.find(f => f.type == t);
		setSelectedType(t);
		setSelectedSubType(option?.subType ?? 0);

		if (t === MfaContactTypes.AdminProvided) {
			setButtonText("Continue to Verification");
		} else {
			setButtonText("Send Code");
		}
	};

	const handleSend = async () => {
		if (selectedType >= 0 && (selectedSubType || selectedType === MfaContactTypes.AdminProvided)) {
			setIsLoading(true);
			setSendError("");

			var selectedOptionValue = selectedType === MfaContactTypes.AdminProvided ? availableOptions.filter(opt => opt.type === selectedType)[0] : availableOptions.filter(opt => opt.type === selectedType && opt.subType === selectedSubType)[0];

			var response = await postAnonymous<MfaResponse>(`/api/mfa/send`, JSON.stringify(selectedOptionValue));
			if (response?.ok && response?.resObj?.success && !response?.resObj?.errorMessage) {
				localStorageUtil.storeItem('mfa-send-response', response.resObj);
			} else {
				setSendError(response?.resObj?.errorMessage ?? "Error sending code.");
				setIsLoading(false);
				return;
			}
			// "MFA Authentication" should just be a placeholder in case something went wrong, it should always be overwritten
			var authTypeQs = `${encodeURIComponent("MFA Options Authentication")}`;
			if (searchParams?.get("AuthType")) {
				authTypeQs = `${searchParams.get("AuthType")}`;
			}

			var navLocation = `/mfa/verify?AuthType=${authTypeQs}`;
			if (searchParams?.get("NextPage")) {
				navLocation += `&NextPage=${searchParams.get("NextPage")}`;
			}
			navigate(navLocation, { replace: false });
		}
	};
    
    return(
        <div id="divMfaContainer" className="container">
            <div id='divMfaInstructions' className="instructions divForm">
                <div className='instructionHeader'>
                    <p>Additional Security Verification</p>
                    <hr/>
                </div>
                { (!isInitialLoading && availableOptions.length) ?
               		<div><p>Please select which method you would like to receive your security code.</p>
					<FormSelect 
						label="Contact Options"
						isLabelHidden={true}
						id="ddlContactOptions"
						onChange={(e) => handleSelectType(e.target.value, null)}
						value={selectedType.toString()}
					>
						{availableOptions.filter(o => o.type === MfaContactTypes.Text).length ? <option value={MfaContactTypes.Text.toString()}>Text Security Code</option> : null}
						{availableOptions.filter(o => o.type === MfaContactTypes.Email).length ? <option value={MfaContactTypes.Email.toString()}>Email Security Code</option> : null}
						{availableOptions.filter(o => o.type === MfaContactTypes.Phone).length ? <option value={MfaContactTypes.Phone.toString()}>Call me with Security Code</option> : null}
						{availableOptions.filter(o => o.type === MfaContactTypes.AdminProvided).length ? <option value={MfaContactTypes.AdminProvided.toString()}>Ask Admin for Security Code</option> : null}
					</FormSelect>
					</div> :
					null
				}

				{ 	(shownOptions.length && selectedType !== MfaContactTypes.AdminProvided && !isInitialLoading) ?
						shownOptions
							.map((o, i) => {
								var label = "";
								var id = o.subType.toString();
								var key = `${i}-${o.type.toString()}-${o.subType.toString()}`;
								if (selectedType === MfaContactTypes.Phone) {
									label = `Call ${o.value}`;
								} else if (selectedType === MfaContactTypes.Text) {
									label = `Text ${o.value}`;
								} else if (selectedType === MfaContactTypes.Email) {
									label = `Email ${o.value}`;
								}
								return (
									<Radio
									checked={o.subType === selectedSubType && o.type === selectedType}
									className="mfa-option"
									id={id}
									label={label}
									onChange={(e) => {handleSelectOption(id);}}
									name={selectedType.toString()}
								  	/>
									//<Form.Check className="mfa-option" label={label} type="radio" name={selectedType.toString()} id={id} key={key} checked={o.subType === selectedSubType && o.type === selectedType} onChange={(e) => {handleSelectOption(id);}} />
								)
							})
					: null
				}	

				{	(selectedType === MfaContactTypes.AdminProvided && shownOptions.length > 0 && !isInitialLoading) ?
						<>
							<p style={{textAlign: 'center'}}>{shownOptions[0].value}</p>
							<p style={{textAlign: 'center'}}>Continue to Verification page once you receive your code.</p>
						</>
					: null
				}

				{ !availableOptions.length && !isInitialLoading ? <p>There are no communication methods associated to this user account. Please contact your system administrator.</p> : null}

				{ (isInitialLoading) ? <BorderSpinner isFooterSavingSpinner/> : null }

				<div id='divMfaButton'>
					{ (!isInitialLoading && availableOptions.length) ? <Button id='btnSend' className='auth-button' variant='outline-primary' type='button' onClick={handleSend} disabled={!selectedSubType && selectedType !== MfaContactTypes.AdminProvided}>{(isLoading) ? <BorderSpinner isFooterSavingSpinner/> : buttonText}</Button> : null }
				</div> 

				{(webDirectory) ? <a id="lnkBack" href={`/${webDirectory}`}>Back</a> : null}

				{(sendError) ? <div id="divErrorMessage" className="Errors">{sendError}</div> : null}

				{(shownOptions.length > 0 && (selectedType === MfaContactTypes.Phone || selectedType === MfaContactTypes.Text)) ? <p style={{marginTop:'50px',fontSize:'70%',color:'#888'}}>* By clicking Send Code, you consent to receive SMS messages (including text messages), and telephone calls (including prerecorded, artificial, and autodialed) from us, our agents, representatives, affiliates, or anyone communicating on our behalf at the specific number(s) you have provided to us.  You certify, warrant, and represent that the telephone number you have provided us is your contact number and not someone else's.  You represent that you are permitted to receive calls and text messages at the telephone number you have provided to us.  You agree to promptly alert us whenever you stop using a telephone number.</p> : null}
            </div>
        </div>
    );
};

export default MfaOptions;