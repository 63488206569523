import { urls } from "./environment-constants";
import { tryGetToken } from './jwt-util';

export async function getAnonymous<T>(path: string): Promise<{status: number, ok: boolean, resObj: T } | null> {
	var headers = new Headers();
	headers.append('Content-Type','application/x-www-form-urlencoded');
    headers.append('x-api-key',process.env.REACT_APP_AUTH_API_KEY);
    headers.append('Access-Control-Request-Headers',"Content-Type, Access-Control-Allow-Origin");
	headers.append('Accept', 'application/json');

    var requestOptions : RequestInit = {
      method: "GET",
      headers: headers,
      redirect: "follow" as const,
      credentials: "include"
    };

	try {
		const response = await fetch(`${urls.apiBaseUrl}${path}`, requestOptions);
		if (response.status === 401) {
			var webDir = await response.text();
			var redirect = "/errors/unauthorized";
			console.error(webDir);
			console.error(JSON.stringify(response.headers));
			if (webDir && webDir.length) {
				console.error(`401 calling getAnonymous('${path}')`);
				console.error(`with requestOptions: ${JSON.stringify(requestOptions)}`);
				if (/^[a-zA-Z0-9]+$/.test(webDir)) {
					redirect = `${redirect}/${webDir}`;
				}
			}
			window.location.href = redirect;
		}
		
		var resObj = response.status === 204 ? {} : await response.json();
		return { status: response.status, ok: (response.status >= 200 && response.status < 300), resObj: resObj };
	} catch (err) {
		console.error(JSON.stringify(err));
		if (err instanceof Error) console.error(err.message);
		return null;
	}
}

export async function postAnonymous<T>(path: string, body: string|URLSearchParams|null): Promise<{status: number, ok: boolean, resObj: T } | null> {
	var headers = new Headers();

	if (body instanceof URLSearchParams) {
		headers.append('Content-Type','application/x-www-form-urlencoded');
	} else {
		headers.append('Content-Type','application/json');
	}
	
    headers.append('x-api-key',process.env.REACT_APP_AUTH_API_KEY);
    headers.append('Access-Control-Request-Headers',"Content-Type, Access-Control-Allow-Origin");
	headers.append('Accept', 'application/json');

    var requestOptions : RequestInit = {
      method: "POST",
      headers: headers,
	  body: body,
      redirect: "follow" as const,
      credentials: "include"
    };
	
	try {
		const response = await fetch(`${urls.apiBaseUrl}${path}`, requestOptions);
		if (response.status === 401) {
			var webDir = await response.text();
			var redirect = "/errors/unauthorized";
			console.error(webDir);
			console.error(JSON.stringify(response.headers));
			if (webDir && webDir.length) {
				console.error(`401 calling postAnonymous('${path}')`);
				console.error(`with requestOptions: ${JSON.stringify(requestOptions)}`);
				if (/^[a-zA-Z0-9]+$/.test(webDir)) {
					redirect = `${redirect}/${webDir}`;
				}
			}
			window.location.href = redirect;
		}
		
		var resObj = await response.json();
		return { status: response.status, ok: (response.status >= 200 && response.status < 300), resObj: resObj };
	} catch (err: any) {
		console.error(err.message);
		console.error(JSON.stringify(err));
		return null;
	}
}

export async function fetchGraphQlAnonymous<T>(query: string, queryName: string): Promise<{status: number, ok: boolean, resObj: T } | null> {
	query = query.replaceAll(/\s+/g, " ");
	
	try {
		var requestOptions : RequestInit = {
			method: "POST",
			headers: { 'Content-Type': 'application/json', 'x-api-key': process.env.REACT_APP_AUTH_API_KEY },
			body: JSON.stringify({ query }),
			credentials: "include"
		  };

		const response = await fetch(`${urls.apiBaseUrl}/graphql`, requestOptions);

		if (response.status === 401) {
			var webDir = await response.text();
			var redirect = "/errors/unauthorized";
			console.error(webDir);
			console.error(JSON.stringify(response.headers));
			if (webDir && webDir.length) {
				console.error(`401 calling fetchGraphQlAnonymous('${query}', '${queryName})`);
				console.error(`with requestOptions: ${JSON.stringify(requestOptions)}`);
				if (/^[a-zA-Z0-9]+$/.test(webDir)) {
					redirect = `${redirect}/${webDir}`;
				}
			}
			window.location.href = redirect;
		}

		const result: { data: any } = await response.json();
		return { status: response.status, ok: (response.status >= 200 && response.status < 300), resObj: result.data[queryName] };
	} catch (err) {
		console.error(JSON.stringify(err));
		return null;
	}
}

export async function getAuthenticated<T>(path: string, unauthorizedRedirect: null|string = null): Promise<{status: number, ok: boolean, resObj: T } | null> {
	var headers = new Headers();
	headers.append('Content-Type','application/x-www-form-urlencoded');
    headers.append('x-api-key',process.env.REACT_APP_AUTH_API_KEY);
    headers.append('Access-Control-Request-Headers',"Content-Type, Access-Control-Allow-Origin");
	var accessToken = await tryGetToken(unauthorizedRedirect);
	headers.append('Authorization', `Bearer ${accessToken}`);
	headers.append('Accept', 'application/json');

    var requestOptions : RequestInit = {
      method: "GET",
      headers: headers,
      redirect: "follow" as const,
      credentials: "include"
    };

	try {
		const response = await fetch(`${urls.apiBaseUrl}${path}`, requestOptions);
		if (response.status === 401) {
			var webDir = await response.text();
			var redirect = "/errors/unauthorized";
			console.error(webDir);
			console.error(JSON.stringify(response.headers));
			if (webDir && webDir.length) {
				console.error(`401 calling getAuthenticated('${path}')`);
				console.error(`with requestOptions: ${JSON.stringify(requestOptions)}`);
				if (/^[a-zA-Z0-9]+$/.test(webDir)) {
					redirect = `${redirect}/${webDir}`;
				}
			}
			window.location.href = redirect;
		}
		var resObj = await response.json();
		return { status: response.status, ok: (response.status >= 200 && response.status < 300), resObj: resObj };
	} catch (err: any) {
		console.error(err.message);
		console.error(JSON.stringify(err));
		return null;
	}
}

export async function postAuthenticated<T>(path: string, body: string|URLSearchParams|null, unauthorizedRedirect: null|string = null): Promise<{status: number, ok: boolean, resObj: T } | null> {
	var headers = new Headers();

	if (body instanceof URLSearchParams) {
		headers.append('Content-Type','application/x-www-form-urlencoded');
	} else {
		headers.append('Content-Type','application/json');
	}
	
    headers.append('x-api-key',process.env.REACT_APP_AUTH_API_KEY);
    headers.append('Access-Control-Request-Headers',"Content-Type, Access-Control-Allow-Origin");
	var accessToken = await tryGetToken(unauthorizedRedirect);
	headers.append('Authorization', `Bearer ${accessToken}`);
	headers.append('Accept', 'application/json');

    var requestOptions : RequestInit = {
      method: "POST",
      headers: headers,
	  body: body,
      redirect: "follow" as const,
      credentials: "include"
    };
	
	try {
		const response = await fetch(`${urls.apiBaseUrl}${path}`, requestOptions);
		if (response.status === 401) {
			var webDir = await response.text();
			var redirect = "/errors/unauthorized";
			console.error(webDir);
			console.error(JSON.stringify(response.headers));
			if (webDir && webDir.length) {
				console.error(`401 calling postAuthenticated('${path}')`);
				console.error(`with requestOptions: ${JSON.stringify(requestOptions)}`);
				if (/^[a-zA-Z0-9]+$/.test(webDir)) {
					redirect = `${redirect}/${webDir}`;
				}
			}
			window.location.href = redirect;
		}
		var resObj = await response.json();
		return { status: response.status, ok: (response.status >= 200 && response.status < 300), resObj: resObj };
	} catch (err) {
		console.error(JSON.stringify(err));
		return null;
	}
}

export async function fetchGraphQlAuthenticated<T>(query: string, queryName: string, unauthorizedRedirect: null|string = null): Promise<{status: number, ok: boolean, resObj: T } | null> {
	query = query.replaceAll(/\s+/g, " ");

	var accessToken = await tryGetToken(unauthorizedRedirect);
	
	try {
		var requestOptions : RequestInit = {
			method: "POST",
			headers: { 'Content-Type': 'application/json', 'x-api-key': process.env.REACT_APP_AUTH_API_KEY, 'Authorization': `Bearer ${accessToken}` },
			body: JSON.stringify({ query }),
			credentials: "include"
		  };

		const response = await fetch(`${urls.apiBaseUrl}/graphql`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', 'x-api-key': process.env.REACT_APP_AUTH_API_KEY, 'Authorization': `Bearer ${accessToken}` },
			body: JSON.stringify({ query }),
			credentials: "include"
		});

		if (response.status === 401) {
			var webDir = await response.text();
			var redirect = "/errors/unauthorized";
			console.error(webDir);
			console.error(JSON.stringify(response.headers));
			if (webDir && webDir.length) {
				console.error(`401 calling fetchGraphQlAuthenticated('${query}', '${queryName})`);
				console.error(`with requestOptions: ${JSON.stringify(requestOptions)}`);
				if (/^[a-zA-Z0-9]+$/.test(webDir)) {
					redirect = `${redirect}/${webDir}`;
				}
			}
			window.location.href = redirect;
		}

		const result: { data: any } = await response.json();
		return { status: response.status, ok: (response.status >= 200 && response.status < 300), resObj: result.data[queryName] };
	} catch (err) {
		console.error(JSON.stringify(err));
		return null;
	}
}
