import { HeaderNavbar, MainFooter } from "@allsynx/components";
import { Outlet } from "react-router-dom";
import Logo from "resources/tbhLogo.png";
import { useContext, useState } from 'react';
import { CompanyInfo } from "types/company-info";
import { fetchGraphQlAnonymous } from "utils/api-util";
import { localStorageUtil } from "utils/local-storage-util";
import { NavigationContext } from "contexts/navigation-context";
import { APP_SERVER_NAME } from "utils/environment-constants";

function Frame(){
	const { footerHasSideBar } = useContext(NavigationContext);
	const [imageSource, setImageSource] = useState<string>("");
	const [webDir, setWebDir] = useState<string>("");

	const setWebDirForLogo = (webDirectory: string) => {
		// if passed as empty or null, check local storage
		if (!webDirectory) {
			const localWebDir = localStorageUtil.getItem<string>("webDir");

			if (localWebDir) {
				setWebDirForLogo(localWebDir);
			} else {
				setImageSource(Logo);
				setWebDir("admin");
			}
		} else {
			if (webDirectory.toLowerCase() === "admin") {
				setImageSource(Logo);
			} else {
				setWebDir(webDirectory);
				fetchGraphQlAnonymous<CompanyInfo>(
						`query {
								companyInfo(webDirectory:"${webDirectory}") {
									logoUrl
								}
							}`
					, "companyInfo"
				)
				.then(response => {
					if (response?.resObj?.logoUrl) {
						setImageSource(response.resObj.logoUrl);
					}
				});
			}
		}
	};
    
    return (
		<>
			<HeaderNavbar imgSrc={imageSource} showImageOnSmallWidth={true} />

			{/* <Outlet context={setImageSource} /> */}
			<Outlet context={setWebDirForLogo} />

			<MainFooter hasSidebar={footerHasSideBar} hostname={APP_SERVER_NAME}/>
		</>
    );
}

export default Frame;